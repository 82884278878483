<template>
  <div>

    <body>
      <div class="responsive"></div>
      <!--[if lte IE 9]>
      <p class="browserupgrade">
        You are using an <strong>outdated</strong> browser. Please upgrade your browser</a> to improve
        your experience and security.
      </p>
    <![endif]-->

      <!-- ======== preloader start ======== -->

      <!-- <div class="preloader">
      <div class="loader">
        <div class="spinner">
          <div class="spinner-container">
            <div class="spinner-rotator">
              <div class="spinner-left">
                <div class="spinner-circle"></div>
              </div>
              <div class="spinner-right">
                <div class="spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      <!-- preloader end -->

      <!-- ======== header start ======== -->
      <header class="header">
        <div class="navbar-area">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg">
                  <a class="navbar-brand" href="index.html">
                    <img src="@/homepage-assets/images/logo/logo.svg" alt="Logo" />
                  </a>
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                  </button>

                  <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul id="nav" class="navbar-nav">
                      <!--ms-auto-->
                      <li class="nav-item">
                        <a class="page-scroll active" href="#home">{{ this.$t('messages.login') }}</a>
                      </li>
                      <li class="nav-item">
                        <a class="page-scroll" href="#features">{{
                          $t('homepage.whats') }}?</a>
                      </li>
                      <li class="nav-item">
                        <a class="page-scroll" href="#about">{{
                          $t('homepage.What_Can_I_Do') }}?</a>
                      </li>

                      <li class="nav-item">
                        <a class="page-scroll" href="#properties">{{ this.$t('general.features') }}</a>
                      </li>
                      <li class="nav-item">
                        <a href="/#/contact">{{
                          $t('homepage.contact') }}</a>
                      </li>
                    </ul>
                  </div>
                  <a :uk-tooltip="`title: ${$t('home.languages')} ; pos: bottom ;offset:0`
                    " class="header-widget-icon" href="javascript:void(0)">
                    <img class="h-23px w-23px rounded-sm" :src="languageFlag || getLanguageFlag" alt="" />
                  </a>
                  <!-- Language dropdown -->
                  <div ref="languageRefs" class="dropdown-notifications dropdown-languages"
                    uk-dropdown=" pos: top-right;mode:click">
                    <!-- notification contents -->
                    <div class="dropdown-notifications-content" data-simplebar>
                      <!-- notiviation list -->
                      <ul style="background-color: white;">
                        <template v-for="(item, i) in languages">
                          <li v-if="item.lang !== activeLanguage" class="navi-item" :class="{
                            'navi-item-active': isActiveLanguage(item.lang),
                          }" :key="i">
                            <a href="#" class="navi-link" v-bind:data-lang="item.lang" v-on:click="selectedLanguage">
                              <span class="symbol symbol-20 mr-3">
                                <img :src="item.flag" alt="" class="h-23px w-23px rounded-sm" />
                              </span>
                              <span class="navi-text">{{ item.name }}</span>
                            </a>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                  <!-- navbar collapse -->

                  <!-- <a href="#" class="main-btn border-btn btn-hover d-none d-sm-block"><i class="lni lni-calendar"></i> Bir
                    Demo Planlayın</a> -->
                  <!-- <a style="border:2px solid #fff !important; font-weight:600;" href="/#/login"
                    class="main-btn border-btn btn-hover d-none d-sm-block"><i class="lni lni-arrow-right-circle"></i>
                    Giriş Yap</a> -->

                  <!-- <div id="lang-menu">
                    <div><span>TR</span></div>
                    <ul>
                        <li><span>TR</span><img src="assets/images/languages/tr.png" alt=""></li>
                        <li><span>EN</span><img src="assets/images/languages/en.png" alt=""></li>
                        <li><span>AZ</span><img src="assets/images/languages/az.png" alt=""></li>
                        <li><span>KZ</span><img src="assets/images/languages/kz.png" alt=""></li>
                        <li><span>RU</span><img src="assets/images/languages/ru.png" alt=""></li>
                        <li><span>GE</span><img src="assets/images/languages/ge.png" alt=""></li>
                        <li><span>RO</span><img src="assets/images/languages/ro.png" alt=""></li>
                    </ul>
                    </div> -->
                </nav>
                <!-- navbar -->
              </div>
            </div>
            <!-- row -->
          </div>
          <!-- container -->
        </div>
        <!-- navbar area -->
      </header>
      <!-- ======== header end ======== -->

      <!-- Video Modal -->
      <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              </button>
              <!-- 16:9 aspect ratio -->
              <div class="ratio ratio-16x9">
                <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always"
                  allow="autoplay"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ======== hero-section start ======== -->
      <section id="home" class="hero-section">
        <div class="container">
          <div class="row align-items-center position-relative">
            <div class="col-lg-6 offset-lg-3">
              <div class="hero-content">
                <img class="softinya-logo" src="@/homepage-assets/images/logo/logo.svg" alt="Softinya LMS">
                <p class="wow fadeInUp mt-2" data-wow-delay=".4s">
                  {{ $t('general.company_platform') }} </p>
                <!-- <a href="javascript:void(0)" class="main-btn btn-white btn-hover wow fadeInUp" data-wow-delay=".6s"><i
                    class="lni lni-calendar"></i> Bir Demo Planlayın</a> -->
                <a style="border:2px solid #fff !important;" href="#properties"
                  class="main-btn border-btn btn-hover wow fadeInUp" data-wow-delay=".6s">{{
                    $t('homepage.explore_features') }}</a>
              </div>
              <div class="hero-img wow fadeInUp" data-wow-delay=".5s">
                <a class="video-btn" data-bs-toggle="modal" data-src="@/homepage-assets/images/softinyalms.mp4"
                  data-bs-target="#myModal"><img class="img-fluid" src="@/homepage-assets/images/icon-video.png" alt="">
                  <span class="circle1"></span>
                </a>
                <img class="img-fluid" src="@/homepage-assets/images/hero/softinya-teaser.png" alt="Softinya LMS" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======== hero-section end ======== -->

      <!-- ======== feature-section start ======== -->
      <section id="features" class="feature-section pt-200">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12">
              <h2 class="section-title text-center d-flex align-items-center justify-content-center">
                <img src="@/homepage-assets/images/logo/logo-2.svg" class="softinya-logo" alt="Softinya LMS"> <span
                  class="logo-tag" style="margin-top:-7px;">ile;</span>
              </h2>
            </div>
            <div class="col-lg-3 col-md-8 col-sm-10">
              <div class="single-feature">
                <div class="icon-soft wow fadeInUp" data-wow-delay=".3s">
                  <img src="@/homepage-assets/images/icon-card-1.webp" alt="">
                </div>
                <div class="content">
                  <p>
                    {{
                      $t('homepage.you_can_manage') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-8 col-sm-10">
              <div class="single-feature">
                <div class="icon-soft wow fadeInUp" data-wow-delay=".4s">
                  <img src="@/homepage-assets/images/icon-card-2.webp" alt="">
                </div>
                <div class="content">
                  <p>
                    <!-- <b>Oryantasyon</b> süreçlerinizi dijitalde hazırlayabilirsiniz. -->
                    {{
                      $t('homepage.orientation_processes') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-8 col-sm-10">
              <div class="single-feature">
                <div class="icon-soft wow fadeInUp" data-wow-delay=".5s">
                  <img src="@/homepage-assets/images/icon-card-3.webp" alt="">
                </div>
                <div class="content">
                  <p>
                    <!-- <b>Zorunlu eğitimlerinizi</b> kolay bir şekilde çalışanlarınıza atayabilir, raporlayabilir ve
                    sertifikalandırabilirsiniz. -->
                    {{
                      $t('homepage.you_can_easily_assign') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-8 col-sm-10">
              <div class="single-feature">
                <div class="icon-soft wow fadeInUp" data-wow-delay=".6s">
                  <img src="@/homepage-assets/images/icon-card-4.webp" alt="">
                </div>
                <div class="content">
                  <p>
                    <!-- <b>Dijital eğitimlerinizi</b> kendiniz hazırlayıp sisteme yükleyebilir ve çalışanlarınıza
                    atayabilirsiniz. -->
                    {{
                      $t('homepage.digital_trainings') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======== feature-section end ======== -->

      <!-- ======== about-section start ======== -->
      <section id="about" class="about-section pt-0">
        <div class="container">
          <div class="row">
            <div class="col-md-6 offset-md-3 text-center pt-100">
              <div class="d-flex align-items-center justify-content-center"><img
                  src="@/homepage-assets/images/logo/logo-2.svg" class="softinya-logo" alt="Softinya LMS">
                <span class="logo-tag">{{ $t('general.with') }}</span>
              </div>
              <div class="section-title">
                <h1 class="color-primary">{{ $t('general.with_softinya') }}</h1>
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6">
              <div class="about-img">
                <img src="@/homepage-assets/images/switch-softinya.png" alt="" class="w-100 wow fadeInRight"
                  data-wow-duration=".75s" data-wow-delay=".5s" />
                <img src="@/homepage-assets/images/about-left-shape.png" alt="" class="shape shape-1" />

              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="about-content">
                <div class="section-title mb-30">
                  <h2 class="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    {{ this.$t('general.oriantation') }}
                  </h2>
                </div>
                <p class="wow fadeInUp mb-25 lead" data-wow-delay=".4s">
                  <span>{{ $t('general.following_challenges') }}</span>
                </p>
                <ul class="wow fadeInUp" data-wow-delay=".4s">
                  <li>{{ this.$t('general.same_topic') }}
                  </li>
                  <li>{{ $t('general.plan_the_time') }}
                  </li>
                  <li>{{ $t('general.planing_the_shifts') }}
                  </li>
                  <li>{{ this.$t('general.process_in_excel') }}
                  </li>
                </ul>

              </div>
            </div>
            <div class="col-md-8 offset-md-2 text-center">
              <p class="lead mb-25 color-primary">{{ $t('general.for_free') }}</p>
              <a style="border:2px solid #7971ff !important;" href="javascript:void(0)"
                class="main-btn btn-hover border-btn wow fadeInUp" data-wow-delay=".6s">{{ this.$t('general.try_now')
                }}</a>
            </div>
          </div>
        </div>
      </section>
      <!-- ======== about-section end ======== -->

      <!-- ======== about2-section start ======== -->
      <section id="about2" class="about-section pt-50">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6">
              <div class="about-content">
                <div class="section-title mb-30">
                  <h2 class="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    {{ this.$t('general.move_your_legal') }}
                  </h2>
                  <p class="wow fadeInUp" data-wow-delay=".4s">
                    {{ this.$t('general.legal_way') }}
                  </p>
                </div>
                <a style="border:2px solid #7971ff !important;" href="javascript:void(0)"
                  class="main-btn btn-hover border-btn d-block wow fadeInUp" data-wow-delay=".6s"><i
                    class="lni lni-book"></i>{{ this.$t('general.review_legal') }}</a>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 order-first order-lg-last">
              <div class="about-img-2">
                <img src="@/homepage-assets/images/woman-holding-softinya-logo.png" alt="" class="w-100 wow fadeInLeft"
                  data-wow-duration=".75s" data-wow-delay=".5s" />
                <img src="@/homepage-assets/images/about-right-shape.png" class="shape shape-1" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======== about2-section end ======== -->

      <!-- ======== cta-section start ======== -->
      <section class="cta-section ">
        <div class="cta-wrapper ">
          <div class="container ">
            <div class="row ">
              <div class="col-md-7 offset-md-3 text-center ">
                <p class="lead mb-25 ">{{ $t('general.us_to_learn') }}</p>
                <a style="border:2px solid #fff !important;" href="javascript:void(0) "
                  class="main-btn btn-hover border-btn wow fadeInUp " data-wow-delay=".3s"><i
                    class="lni lni-envelope"></i> {{
                      $t('homepage.get_in_touch') }}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======== cta-section end ======== -->

      <!-- ======== feature-section start ======== -->
      <section id="why" class="feature-extended-section pt-0 ">
        <div class="feature-extended-wrapper ">
          <div class="container">
            <div class="row ">
              <div class="col-lg-4 col-md-6 ">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".3s">
                    <img src="@/homepage-assets/images/softinya-1.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3>{{ this.$t('general.upload_now') }}</h3>
                    <p>
                      {{ $t('general.resources_you_produce') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 ">
                <div class="single-feature-extended">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-2.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3>{{ this.$t('general.the_certificate') }}</h3>
                    <p>
                      {{ $t('general.your_certificates') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 ">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-3.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3>{{ $t('general.make_reports') }}</h3>
                    <p>
                      {{ this.$t('general.flexible_reports') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 ">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-4.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3>{{ this.$t('general.class_trainings') }}</h3>
                    <p>
                      {{ $t('general.operational_burdens') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 ">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-5.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3>{{ this.$t('general.save_time') }}</h3>
                    <p>
                      {{ $t('general.operational_loads') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-6.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3> {{ $t('general.mobile') }}</h3>
                    <p>
                      {{ this.$t('general.mobile_app') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow fadeInUp " data-wow-delay=".5s">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-7.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3>{{ $t('general.blue_grey') }}</h3>
                    <p>
                      {{ this.$t('general.blue_or') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 ">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-8.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3>{{
                      $t('homepage.create_blended') }}</h3>
                    <p>
                      {{
                        $t('homepage.ready_catalog_trainings') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 ">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-9.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content">
                    <h3> {{
                      $t('homepage.ready_catalog_trainings') }}</h3>
                    <p>
                      {{
                        $t('homepage.with_our_digital_training_catalogue') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 ">
                <div class="single-feature-extended ">
                  <div class="icon-soft wow fadeInUp " data-wow-delay=".5s">
                    <img src="@/homepage-assets/images/softinya-10.png" class="img-fluid"
                      alt="Kendi Eğitimlerinizi Yükleyin">
                  </div>
                  <div class="content ">
                    <h3>{{ this.$t('general.train_your_custemers') }}</h3>
                    <p>
                      {{ $t('general.their_training') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======== feature-section end ======== -->

      <!-- ======== properties-section start ======== -->
      <section id="properties" class="properties-section pt-120 ">
        <div class="properties-wrapper container">
          <div class="row">
            <div class="col-md-6 offset-md-3 text-center pb-25">
              <div class="d-flex align-items-center justify-content-center"><img
                  src="@/homepage-assets/images/logo/logo-2.svg" class="softinya-logo" alt="Softinya LMS">
                <span class="logo-tag">'in</span>
              </div>
              <div class="section-title">
                <h1 class="color-primary">Özellikleri</h1>
              </div>
            </div>
          </div>

          <div class="nav-pills-wrapper">
            <div class="nav flex-column nav-pills me-3 col-md-6 col-12 wow fadeInRight" data-wow-delay=".5s"
              id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button class="nav-link active" id="v-pills-kullanici-entegrasyonlari-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-kullanici-entegrasyonlari" type="button" role="tab"
                aria-controls="v-pills-kullanici-entegrasyonlari" aria-selected="true"><i class="lni lni-plus"></i>
                {{ this.$t('general.user_integrations') }}</button>
              <button class="nav-link" id="v-pills-icerik-olusturma-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-icerik-olusturma" type="button" role="tab"
                aria-controls="v-pills-icerik-olusturma" aria-selected="false"><i class="lni lni-plus"></i>{{
                  this.$t('general.creating_content') }}</button>
              <button class="nav-link" id="v-pills-otomasyon-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-otomasyon" type="button" role="tab" aria-controls="v-pills-otomasyon"
                aria-selected="false"><i class="lni lni-plus"></i>{{ $t('general.automation') }}</button>
              <button class="nav-link" id="v-pills-oyunlastirma-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-oyunlastirma" type="button" role="tab" aria-controls="v-pills-oyunlastirma"
                aria-selected="false"><i class="lni lni-plus"></i>{{ $t('general.for_gamification') }}</button>
              <button class="nav-link" id="v-pills-anketler-tab" data-bs-toggle="pill" data-bs-target="#v-pills-anketler"
                type="button" role="tab" aria-controls="v-pills-anketler" aria-selected="false"><i
                  class="lni lni-plus"></i>
                {{ this.$t('general.for_surveys') }}</button>
              <button class="nav-link" id="v-pills-sinavlar-tab" data-bs-toggle="pill" data-bs-target="#v-pills-sinavlar"
                type="button" role="tab" aria-controls="v-pills-sinavlar" aria-selected="false"><i
                  class="lni lni-plus"></i>
                {{ $t('general.for_exams') }}</button>
              <button class="nav-link" id="v-pills-raporlar-tab" data-bs-toggle="pill" data-bs-target="#v-pills-raporlar"
                type="button" role="tab" aria-controls="v-pills-raporlar" aria-selected="false"><i
                  class="lni lni-plus"></i>
                {{ this.$t('general.for_reports') }}</button>
            </div>
            <div class="tab-content col-md-6 col-12 wow fadeInLeft" data-wow-delay=".5s" id="v-pills-tabContent">
              <div class="tab-pane fade show active" id="v-pills-kullanici-entegrasyonlari" role="tabpanel"
                aria-labelledby="v-pills-ullanici-entegrasyonlari-tab">
                <img src="@/homepage-assets/images/softinya-prop-1.webp"
                  alt="Sofitanya LMS ile Kullanıcı Entegrasyonları">
                <p>{{ this.$t('general.are_registered') }}</p>
              </div>
              <div class="tab-pane fade" id="v-pills-icerik-olusturma" role="tabpanel"
                aria-labelledby="v-pills-icerik-olusturma-tab">
                <img src="@/homepage-assets/images/softinya-prop-2.webp" alt="Sofitanya LMS ile İçerik Oluşturma">
                <p>Kendi oluşturduğunuz video, ses dosyası, Power Point, PDF vb. dosyaları kolay bir şekilde Softinya
                  LMS’e
                  yükleyip çalışanlarınız ile paylaşabilirsiniz.</p>
              </div>
              <div class="tab-pane fade" id="v-pills-otomasyon" role="tabpanel" aria-labelledby="v-pills-otomasyon-tab">
                <img src="@/homepage-assets/images/softinya-prop-3.webp" alt="Sofitanya LMS ile Otomasyon">
                <p>Göreve, unvana, pozisyona göre eğitimleri çalışanlarınıza otomatik olarak atayabilirsiniz.</p>
              </div>
              <div class="tab-pane fade" id="v-pills-oyunlastirma" role="tabpanel"
                aria-labelledby="v-pills-oyunlastirma-tab">
                <img src="@/homepage-assets/images/softinya-prop-4.webp" alt="Sofitanya LMS ile Oyunlaştırma">
                <p>Softinya LMS'in oyunlaştırma kurguları ile çalışanlarınıza eğlenceli, motivasyonu yüksek, tatlı
                  rekabetçi
                  ve öğretici öğrenme ortamı sunabilirsiniz.</p>
              </div>
              <div class="tab-pane fade" id="v-pills-anketler" role="tabpanel" aria-labelledby="v-pills-anketler-tab">
                <img src="@/homepage-assets/images/softinya-prop-5.png" alt="Sofitanya LMS ile Anketler">
                <p>Anket modülü ile hem eğitimlerin değerlendirilmesi ile ilgili bilgi toplayabilir hem de genel ihtiyaç
                  analizleri yapabilirsiniz.</p>
              </div>
              <div class="tab-pane fade" id="v-pills-sinavlar" role="tabpanel" aria-labelledby="v-pills-sinavlar-tab">
                <img src="@/homepage-assets/images/softinya-prop-6.webp" alt="Sofitanya LMS ile Sınavlar">
                <p>Eğitim sonu sınavlarla sınav süreçlerinizi dijitalleştirin.</p>
              </div>
              <div class="tab-pane fade" id="v-pills-raporlar" role="tabpanel" aria-labelledby="v-pills-raporlar-tab">
                <img src="@/homepage-assets/images/softinya-prop-7.webp" alt="Sofitanya LMS ile Raporlar">
                <p>Softinya LMS’in gelişmiş rapor modülü ile tüm eğitim süreçlerinizi detaylıca analiz ederek hem eğitim
                  süreçlerinizi iyileştirebilir hem de çalışanlarınızın gelişimini ölçümleyebilirsiniz.</p>
              </div>


            </div>
          </div>
        </div>
      </section>
      <!-- ======== properties-section end ======== -->

      <!-- ======== footer start ======== -->
      <footer class="footer ">
        <div class="container ">
          <div class="widget-wrapper ">
            <div class="row ">
              <div class="col-xl-4 col-lg-4 col-md-6 ">
                <div class="footer-widget ">
                  <div class="logo mb-30 ">
                    <a href="index.html">
                      <img src="@/homepage-assets/images/logo/logo.svg" alt="Softinya LMS" />
                    </a>
                  </div>
                  <p class="desc mb-30 text-white ">
                    <a href="mailto:info@softinya.com"><i class="lni lni-envelope"></i> info@softinya.com</a>
                    <a href="tel:0(212)-267-53-73"><i class="lni lni-phone"></i> (0212) 267 53 73</a>
                    <a href="tel:0(534)-522-08-59"><i class="lni lni-whatsapp"></i> (0534) 522 08 59</a>
                  </p>
                </div>
              </div>

              <div class="col-xl-2 col-lg-2 col-md-6 ">
                <div class="footer-widget mt-25">
                  <ul class="links ">
                    <li><a href="#home">{{ this.$t('messages.login') }}</a></li>
                    <li><a href="#features">{{
                      $t('homepage.whats') }}?</a></li>
                    <li><a href="#about">{{
                      $t('homepage.What_Can_I_Do') }}?</a></li>
                  </ul>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 ">
                <div class="footer-widget mt-25">
                  <ul class="links ">
                    <li><a href="#properties">{{
                      $t('homepage.features') }}</a></li>
                    <li><a href="#contact">{{
                      $t('homepage.contact') }}</a></li>
                    <li><a href="#policies">{{
                      $t('homepage.agreements') }}</a></li>
                  </ul>
                </div>
              </div>

              <div class="col-xl-3 col-lg-3 col-md-6 ">
                <div class="footer-widget ">
                  <ul class="socials mt-80">
                    <li>
                      <a target="_blank" href="https://www.instagram.com/anzeraofficial">
                        <i class="lni lni-instagram-filled "></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.linkedin.com/company/anzeraogrenmeteknolojileri">
                        <i class="lni lni-linkedin-original "></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </footer>
      <!-- ======== footer end ======== -->

      <!-- ======== scroll-top ======== -->
      <a href="# " class="scroll-top btn-hover ">
        <i class="lni lni-chevron-up "></i>
      </a>
    </body>
  </div>
</template>
<script>
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "homepage",
  data() {
    return {
      languages: i18nService.languages,
      languageFlag: "",
    }
  },
  computed: {
    userInfo: {
      get: function () {
        return JSON.parse(this.secureLocalStorage('user'));
      },
      set: function (newValue) { },
    },
    isSuperAdmin() {
      let isAdmin = false;
      if (this.userInfo && this.userInfo.roles && this.userInfo.roles.length) {
        isAdmin = !!this.userInfo.roles.find(item => item.id == 1)
      }
      return isAdmin;
    },
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },

  methods: {
    selectedLanguage(e) {
      if (e) {
        const el = e.target.closest(".navi-link");
        const lang = el.getAttribute("data-lang");

        i18nService.setActiveLanguage(lang);

        this.$emit(
          "language-changed",
          this.languages.find((val) => {
            return val.lang === lang;
          })
        );
        window.location.reload();
      } else {

      }
    },
    isActiveLanguage(current) {
      return this.activeLanguage === current;
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
  },
  mounted() {
    let subdomain = window.location.host.split('.')[0]

    if ((subdomain !== 'www' && subdomain !== 'localhost:8080') || window.location.pathname !== '/') {
      // if ((subdomain !== 'www' && subdomain !== 'localhost:8080') || window.location.hash !== '#/homepage') {
      this.$router.push({ path: "/dashboard" })
    }

  }
};


</script>

<style  src='../../homepage-assets/css/bootstrap.min.css' scoped></style>
<style  src='../../homepage-assets/css/animate.css' scoped></style>
<style  src='../../homepage-assets/css/main.css' scoped></style>
<style  src='../../homepage-assets/css/custom.css' scoped></style>


<!-- <link rel="stylesheet" href="@/homepage-assets/css/bootstrap.min.css" />
<link rel="stylesheet" href="@/homepage-assets/css/lineicons.css" />
<link rel="stylesheet" href="@/homepage-assets/css/animate.css" />
<link rel="stylesheet" href="@/homepage-assets/css/main.css" />
    <link rel="stylesheet" href="@/homepage-assets/css/custom.css" /> */ -->

<!-- @import '../../homepage-assets/css/lineicons.css';
@import '../../homepage-assets/css/animate.css';
@import '../../homepage-assets/css/main.css';
@import '../../homepage-assets/css/custom.css'; -->

